import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { filter, first, takeUntil, tap } from 'rxjs/operators';

import { ConfirmModalComponent } from '../../../components/actions/confirm/confirm.component';
import { UnitOfWorkActions } from '../../../store/actions';
import { UnitOfWork } from '../../../store/models';
import { AppState } from '../../../store/reducers';
import { UnitOfWorkSelectors } from '../../../store/selectors';

@Component({
  selector: 'lib-unit-of-work-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
})
export class UnitOfWorkListComponent implements OnInit, OnDestroy {
  form: UntypedFormGroup;
  @Input() trackingMode: boolean;
  @Input() unitOfProductionId: number;
  unitOfWork$: Observable<UnitOfWork[]>;
  private _destroy$: Subject<boolean> = new Subject();

  constructor(private _store: Store<AppState>, private _fb: UntypedFormBuilder, private _dialog: MatDialog) {}

  archiveToggle(unitOfWork: UnitOfWork) {
    this._store.dispatch(UnitOfWorkActions.update({ id: unitOfWork.id, values: { archived: !unitOfWork.archived } }));
  }

  delete(unitOfWork: UnitOfWork) {
    const ref = this._dialog.open(ConfirmModalComponent, {
      data: {
        title: 'Êtes vous sûr de vouloir supprimer cette unité de travail',
      },
    });

    ref
      .afterClosed()
      .pipe(
        first(),
        filter(confirm => confirm),
        tap(() => this._store.dispatch(UnitOfWorkActions.remove({ id: unitOfWork.id })))
      )
      .subscribe();
  }

  isVisible(unitOfWork: UnitOfWork): boolean {
    return !this.trackingMode || unitOfWork.enabled;
  }

  ngOnDestroy() {
    this._destroy$.next(true);
  }

  ngOnInit(): void {
    if (this.unitOfProductionId === undefined) {
      throw Error('The Id of the Unit Of production is required');
    }

    this.unitOfWork$ = this._store.pipe(select(UnitOfWorkSelectors.getAllByUnitOfProduction(this.unitOfProductionId)));

    this._store.dispatch(UnitOfWorkActions.loadByUnitOfProduction({ unitOfProductionId: this.unitOfProductionId }));

    this.form = this._fb.group(
      {
        name: ['', Validators.required],
      },
      { updateOn: 'blur' }
    );

    this.form.valueChanges
      .pipe(
        takeUntil(this._destroy$),
        filter(() => this.form.valid),
        tap(({ name }) => {
          this._store.dispatch(UnitOfWorkActions.create({ unitOfProductionId: this.unitOfProductionId, name }));

          this.form.setValue({ name: '' });
          this.form.markAsPristine();
        })
      )
      .subscribe();
  }

  onDrop(data) {
    this._store.dispatch(UnitOfWorkActions.move({ id: +data.id, unitOfProductionId: this.unitOfProductionId, position: data.position }));
  }

  update(unitOfWork: UnitOfWork, values: Partial<UnitOfWork>) {
    this._store.dispatch(UnitOfWorkActions.update({ id: unitOfWork.id, values }));
  }
}
