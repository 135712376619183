<div class="title">Selectionner la source de danger dans laquelle dupliquer cette UT</div>

<div *ngIf="dangerSources$ | async as dangerSources" [formGroup]="form" class="content">
  <lib-select [options]="dangerSourceOptions" formControlName="dangerSource"></lib-select>
</div>
<div *ngIf="unitOfWorks$ | async as unitOfWorks" [formGroup]="form" class="content">
  <lib-select [options]="unitOfWorkOptions" formControlName="unitOfWork"></lib-select>
</div>

<div class="action-wrapper">
  <span (click)="cancel()" class="button shadow">Annuler</span>
  <span (click)="confirm()" class="button" [ngClass]="{ disabled: !form.valid }">Valider</span>
</div>
