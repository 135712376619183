<ng-container *appDuerTitle>
  <h1>Planification</h1>
</ng-container>

<div class="actions">
  <a href="#" class="button" (click)="print($event)">Imprimer</a>
</div>
<div class="print-header" *ngIf="unitOfProduction$ | async as unitOfProduction">
  Planification :
  <lib-text-format [text]="unitOfProduction.name"></lib-text-format>
  ({{ currentDate }})
</div>

<ng-container *ngIf="filtersOptions$ | async as filtersOptions">
  <app-planning-filter
    *ngIf="filters$ | async as filters"
    [riskOptions]="filtersOptions.risk"
    [unitOfWorkOptions]="filtersOptions.unitOfWork"
    [responsibleOptions]="filtersOptions.responsible"
    (filterRequested)="changeFilter($event)"
    [filters]="filters"
  ></app-planning-filter>
</ng-container>

<div class="header">
  <div class="counter"></div>
  <div class="risk">
    <strong>
      Risques
      <small>et</small>
    </strong>
    <small>Sources de danger</small>
  </div>
  <div class="unit-of-work">Unité de travail</div>
  <div class="quotation">Cotation</div>
  <div class="top"></div>
  <div class="measure" *ngIf="hasPeriodicFilter">Mesure</div>
  <div class="measure" *ngIf="!hasPeriodicFilter">Mesure préconisée</div>
  <div class="periodic" *ngIf="hasPeriodicFilter">Périodique</div>
  <div class="date" *ngIf="hasPeriodicFilter">Date</div>
  <div class="date" *ngIf="!hasPeriodicFilter">Date prévue</div>
  <div class="responsible">Resp.</div>
</div>
<ng-container *ngIf="hydratedMeasures$ | async as hydratedMeasures">
  <div *ngFor="let hydratedMeasure of hydratedMeasures; let i = index" class="row">
    <app-planning-item
      [hydratedMeasure]="hydratedMeasure"
      [hasPeriodicFilter]="hasPeriodicFilter"
      [index]="i"
      (redirectRequest)="redirectToDangerSource(hydratedMeasure)"
    ></app-planning-item>
  </div>
</ng-container>
