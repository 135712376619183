<ng-container *appDuerTitle>
  <h1>Documents</h1>
</ng-container>

<ng-container *ngIf="unitOfProduction$ | async as unitOfProduction">
  <div class="global-document">
    <div class="block">
      <div class="title">PDF par risque professionnels</div>
      <div class="text">
        Cette version est celle devant être mise à disposition des personnes pour lesquelles le code du travail définit un accès obligatoire
        (salariés, CSE, inspection du travail, ...)
      </div>
      <div class="subtitle">
        PDF du DUER par risques professionnels
        <a [libRiskAssessmentExport]="unitOfProduction" class="button">Télécharger</a>
      </div>
      <div class="subtitle">
        PDF du sommaire par risques professionnels
        <a href="" target="_blank" class="button">Télécharger</a>
      </div>
    </div>
    <ng-container *ngIf="contract$ | async as contract">
      <div class="block" *ngIf="contract.methodologicalPointDocument">
        <div class="title">PDF du repère methodologique</div>
        <a [href]="contract.methodologicalPointDocument" target="_blank" class="button">Télécharger</a>
      </div>
    </ng-container>
  </div>

  <div class="by-unit-of-work" *ngIf="unitOfWorks$ | async as unitOfWorks">
    <div class="block" [formGroup]="form">
      <div class="title">PDF par unité de travail</div>
      <div class="text">Attention, l'impression par unité de travail ne constitue pas un document unique complet.</div>
      <div class="subtitle">
        Veuillez sélectionner les unités de travail
        <br />
        concernées pour votre PDF
      </div>
      <lib-checkbox-group formControlName="selected">
        <lib-line-item *ngFor="let unitOfWork of unitOfWorks" class="contrast-reverse" [ngClass]="{ disabled: !isChecked(unitOfWork.id) }">
          <lib-checkbox-input [value]="unitOfWork.id" class="name">
            <label [textFormat]="unitOfWork.name"></label>
          </lib-checkbox-input>
          <span class="counter">{{ unitOfWork.workforce }}</span>
        </lib-line-item>
      </lib-checkbox-group>
      <a href="" target="_blank" class="button" [ngClass]="{ disabled: !form.valid }" (click)="getByUnitOfWork(unitOfProduction, $event)">
        Télécharger
      </a>
    </div>
  </div>
</ng-container>
