<span class="close" (click)="cancel()"></span>

<div class="title">{{ title }}</div>

<div class="columns" [formGroup]="form">
  <div class="column">
    <div class="column-title">{{ selectorTitle }}</div>
    <div class="column-content">
      <ng-container formGroupName="existing">
        <lib-line-item *ngFor="let dangerSource of items">
          <lib-checkbox-input [formControlName]="dangerSource.id">
            <label [textFormat]="dangerSource.name"></label>
          </lib-checkbox-input>
        </lib-line-item>
      </ng-container>
      <ng-container formGroupName="reference">
        <lib-line-item *ngFor="let refDangerSource of refItems" [ngClass]="{ 'already-added': alreadyAdded(refDangerSource) }">
          <lib-checkbox-input [formControlName]="refDangerSource.id">
            <label [textFormat]="refDangerSource.name"></label>
          </lib-checkbox-input>
        </lib-line-item>
      </ng-container>
    </div>
  </div>

  <div class="column">
    <div class="column-title">Nouvelles sources de danger et leurs mesures que vous rédigez</div>
    <div class="column-content">
      <ng-container formArrayName="custom">
        <lib-line-item *ngFor="let group of customGroups" [formGroup]="group">
          <lib-checkbox-input formControlName="enabled"></lib-checkbox-input>
          <lib-editable-data>
            <span libEditableDataLabel></span>
            <input libEditableDataInput formControlName="name" />
          </lib-editable-data>
        </lib-line-item>
      </ng-container>
    </div>
    <div class="item-create" [formGroup]="addForm">
      <lib-editable-data [linkedData]="false">
        <span libEditableDataLabel>Ajouter une source de danger</span>
        <input libEditableDataInput placeholder="Nom de la source de danger" formControlName="name" />
      </lib-editable-data>
    </div>
  </div>
</div>

<div class="action-wrapper">
  <button class="button strong" (click)="submit()" [disabled]="!form.valid">Valider</button>
</div>
