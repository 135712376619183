import { HttpClient } from '@angular/common/http';
import { Directive, HostListener, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { filter, first, tap } from 'rxjs/operators';
import { ExportModalComponent } from '../components/unit-of-production/export-modal/export-modal.component';
import { DateHelper } from '../helpers/date.helper';
import { UnitOfProduction } from '../store/models';

type ExportData = {
  date?: Date;
  display: boolean;
  signature: string;
};

@Directive({
  selector: '[libRiskAssessmentExport]',
})
export class RiskAssessmentExportDirective {
  @Input() interactive = true;
  @Input() libRiskAssessmentExport: UnitOfProduction;

  constructor(private _dialog: MatDialog, private _http: HttpClient) {}

  @HostListener('click') onClick() {
    if (!this.interactive) {
      this._export();
      return;
    }

    const dialogRef = this._dialog.open(ExportModalComponent, {
      data: {
        date: this.libRiskAssessmentExport.riskAssessmentExportDate || new Date(),
      },
    });

    dialogRef
      .afterClosed()
      .pipe(
        first(),
        filter(values => values !== null),
        tap(values => this._export(values))
      )
      .subscribe();

    return false;
  }

  private _export(data?: ExportData) {
    const url = this.libRiskAssessmentExport.riskAssessmentExportUrl;
    const params = data
      ? {
          riskAssessmentExportDate: data.date ? DateHelper.formatToDate(data.date).toISOString() : null,
          displayRiskAssessmentExportDate: data.display,
          riskAssessmentSignature: data.signature,
        }
      : { riskAssessmentExportDate: null, displayRiskAssessmentExportDate: false, riskAssessmentSignature: '' };

    this._http
      .post(url, params, { observe: 'response', responseType: 'blob' })
      .pipe(
        first(),
        tap(({ headers, body }) => {
          const contentDisposition: string = headers.get('content-disposition');
          const regex = /(?:filename=")(.+)(?:;?")/;
          const filename = regex.exec(contentDisposition)[1];

          const downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(body);
          downloadLink.setAttribute('download', filename);

          document.body.appendChild(downloadLink);
          downloadLink.click();
          downloadLink.remove();
        })
      )
      .subscribe();
  }
}
