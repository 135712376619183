<ng-container *ngIf="dangerSources$ | async as dangerSources">
  <div class="header">
    <div class="title">
      Sources de danger
      <span class="count">{{ getEnabledDangerSourcesCount(dangerSources) }}</span>
    </div>

    <ng-container *ngIf="trackingMode">
      <span class="count-label">Mesures existantes</span>
      <span class="count-label">Mesures mises en oeuvre</span>
      <span class="count-label multi">Mesures préconisées</span>
      <span class="count-label">Mesures en cours</span>
    </ng-container>
  </div>

  <lib-drop-zone (dropItem)="onDrop($event)">
    <ng-container *ngFor="let dangerSource of dangerSources">
      <lib-danger-source-item
        *ngIf="isVisible(dangerSource); else ghost"
        [dangerSource]="dangerSource"
        [dangerSourceItemUrlMask]="dangerSourceItemUrlMask"
        [trackingMode]="trackingMode"
        (update)="update(dangerSource, $event)"
        (deleteRequest)="delete(dangerSource)"
        (duplicateRequest)="duplicate(dangerSource)"
        [dragData]="{ id: dangerSource.id, test: 'truc' }"
        libDragItem
      ></lib-danger-source-item>

      <ng-template #ghost>
        <div libDragItem></div>
      </ng-template>
    </ng-container>
  </lib-drop-zone>

  <div class="danger-source-create" *ngIf="refDangerSources$ | async as refDangerSources">
    <a href="#" class="create-link" (click)="openCreate($event, dangerSources, refDangerSources)">Ajouter une source de danger</a>
  </div>
</ng-container>
