import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DangerSource, RefDangerSource } from '../../../store/models';
import { ItemCreateDirective } from '../../actions/create/create.component';

@Component({
  selector: 'lib-danger-source-create',
  templateUrl: './create.component.html',
  styleUrls: ['../../actions/create/create.component.scss'],
})
export class DangerSourceCreateComponent extends ItemCreateDirective<DangerSource, RefDangerSource> implements OnInit, OnDestroy {
  public dangerSources: DangerSource[] = [];
  get title() {
    return this.trackingMode
      ? 'Vous pouvez choisir des nouvelles sources de danger pré-rédigées ou créer de nouvelles sources de danger'
      : 'Vous pouvez choisir des sources de danger issues du référentiel ou créer de nouvelles sources de danger';
  }

  get selectorTitle() {
    return this.trackingMode
      ? 'Nouvelles sources de danger et leurs mesures prérédigées par nos experts et modifiables'
      : 'Nouvelles sources de danger issues du référentiel';
  }

  constructor(
    dialogRef: MatDialogRef<DangerSourceCreateComponent>,
    fb: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA)
    data: {
      dangerSources: DangerSource[];
      refDangerSources: RefDangerSource[];
      trackingMode: boolean;
      closeFn: (CreateResponse) => void;
    }
  ) {
    super(dialogRef, fb, data.trackingMode, data.dangerSources, data.closeFn);

    this.dangerSources = data.dangerSources;
    const refIds = this.dangerSources
      .filter(({ refDangerSourceId }) => refDangerSourceId !== null)
      .map(({ refDangerSourceId }) => refDangerSourceId);
    this.refItems = data.refDangerSources;
  }

  alreadyAdded({ id }: RefDangerSource): boolean {
    return this.dangerSources.some(({ refDangerSourceId }) => id === refDangerSourceId);
  }
}
